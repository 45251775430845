@use '@carbon/react';
@use '@carbon/styles';
@use '@carbon/styles/scss/grid';
@use '@carbon/type';

@include grid.flex-grid();

$primary: #0f62fe;
$dark: #525252;
$gray: #f8f8f8;
$gray-100: #d9d9d9;
$gray-200: #fcfeff;
$gray-300: #eaeaea;
$gray-400: #f0f0f0;
$dark-300: #d1d2d3;
$black: #000000;
$red: #da1e28;

a {
  text-decoration: none;
  color: $black;
}

.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.w-100 {
  width: 100%;
}
.cds--btn {
  padding: 10px 20px;
  justify-content: center;
  min-height: auto;
  img {
    margin-right: 5px;
  }
  span {
    margin-left: 10px;
  }
  &.cds--btn--danger {
    background-color: $red;
  }
}
.cds--padding-0 {
  padding: 0;
}
.cds--date-picker,
.cds--date-picker-container,
.cds--date-picker-input__wrapper,
.cds--date-picker__input,
.cds--text-input,
.cds--date-picker__input,
.cds--text-area,
.cds--date-picker.cds--date-picker--single .cds--date-picker__input {
  width: 100%;
}
.cds--modal {
  z-index: 99999;
  padding: 0 15px;
  .cds--modal-container {
    height: auto;
    position: static;
    .cds--modal-content {
      margin: 0;
      padding-bottom: 10px;
    }
  }
}
.swiper {
  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: #fff;
    @media screen and (max-width: 767px) {
      font-size: 30px;
    }
  }
}
.app-bar{
  display: flex;
  justify-content: space-between;
  padding: 8px 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  overflow: hidden;
  background-color: #ededf4 !important;
  .logo {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
    span {
      font-weight: bold;
    }
  }
}
.main-content {
  margin-top: 55px !important;
  padding: 1rem 0.5rem;
  background-color: white;
  border-radius: 5px;
  min-height: calc(100vh - 55px);
  @media screen and (min-width: 768px) {
    margin-left: 266px;
    margin-top: 0;
  }
  @media screen and (max-width: 767px) {
    margin: 55px 5px 0px !important;
  }
}
.cds--header {
  padding: 20px 2rem;
  border: 0;
  height: auto;
  @media screen and (min-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    padding: 3px 1rem;
    left: auto;
    z-index: 99999;
    background-color: transparent;
  }
  .cds--header__action {
    border: none;
    background-color: transparent;
  }
}
.side-nav {
  height: auto;
  transition: all 0.5s ease-in-out;
  margin: 55px 10px 0px 0px;
  border-radius: 5px;
  @media screen and (min-width: 768px) {
    top: 0;
  }
  @media screen and (max-width: 767px) {
    margin-top: 0px;
    left: -16rem;
    &.sidebar-open {
      left: 0;
    }
  }
  .cds--side-nav__items {
    .cds--side-nav__link{
      padding: 0 !important;
      margin: 2px 10px;
      border-radius: 5px;
      .cds--side-nav__link-text{
        padding: 5px 15px;
      }
    }
    .active-menu{
      background-color: #d0e7f0;
    }
    .side-nav-bottom {
      flex: 0 0 auto;
    }
    svg,
    img {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .logged-user {
    padding: 10px 20px;
    @media screen and (max-width: 767px) {
      display: none;
    }
    h6 {
      font-weight: bold;
      font-size: 12px;
    }
    span {
      font-size: 10px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 30px 0;
  @media screen and (min-width: 576px) {
    background-color: #f6f6f6;
  }
  .logo {
    text-align: center;
    display: none;
    margin-bottom: 20px;
    span {
      display: block;
      font-weight: bold;
      margin-top: 10px;
    }
    @media screen and (max-width: 575px) {
      display: block;
    }
  }
  .login-box {
    margin: 0 auto;
    max-width: 325px;
    background-color: #fff;
    padding: 16px;
    width: 100%;

    .title {
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 20px;
    }

    .remember {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      .remember-inner {
        display: flex;
        align-items: center;
        .cds--checkbox-wrapper {
          flex: 0 0 auto;
          margin-right: 5px;
          .cds--checkbox-label-text {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
      .forgot-password-text {
        color: $dark;
      }
    }
    .divider-text {
      position: relative;
      text-align: center;
      font-size: 14px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 7px;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.2);
      }
      span {
        background-color: #fff;
        padding: 0 10px;
        position: relative;
        z-index: 1;
      }
    }
    .external-login {
      display: flex;
      align-items: center;
      overflow: hidden;
      button {
        width: calc(50% - 5px);
        flex: 0 0 calc(50% - 5px);
      }
      .sign-in-button {
        width: calc(50% - 5px);
        flex: 0 0 calc(50% - 5px);
        position: relative;
        height: 40px;
        overflow: hidden;
        > div {
          opacity: 0;
          cursor: pointer;
          transform: scale(1.5);
        }
        &:before {
          background-image: url("./Assets/Images/google-icon.svg");
          background-repeat: no-repeat;
          background-position: 32px center;
          content: attr(data-title);
          position: absolute;
          border: 1px solid #0f62fe;
          pointer-events: none;
          width: 100%;
          height: 100%;
          text-align: center;
          padding: 10px 20px 10px 35px;
          font-size: 14px;
          color: #0f62fe;
          background-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &:hover {
          &:before {
            background-color: #0f62fe;
            color: #fff;
          }
        }
      }
    }
    .bottom-text {
      padding-top: 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      margin-top: 10px;
      p {
        font-size: 14px;
      }
      a {
        font-weight: bold;
      }
    }
  }
}
.site-heading {
  .page-title-wrap {
    margin: 10px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .page-title {
      font-weight: bold;
      font-size: 30px;
    }
  }
}
.cds--breadcrumb{
  margin: 0 5px;
  display: flex !important;
}
.property-wrap {
  padding-top: 30px;
}
.property-box {
  margin-bottom: 30px;
  border: 1px solid rgba(34, 34, 34, 0.2);
  .property-img {
    position: relative;
    > img {
      width: 100%;
      display: block;
      height: 150px;
      object-fit: cover;
      object-position: center;
    }
    .property-option {
      position: absolute;
      right: 10px;
      top: 15px;
      span {
        display: block;
        margin-bottom: 10px;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 4px;
        img {
          width: 100%;
        }
      }
    }
  }
  .property-info {
    padding: 12px;
    > span {
      font-size: 12px;
      margin-bottom: 10px;
      display: block;
    }
    h5 {
      font-size: 18px;
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      color: rgba(34, 34, 34, 0.5);
    }
    .property-features {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      .property-facility {
        display: flex;
        align-items: center;
        p {
          color: rgba(34, 34, 34, 0.5);
          margin-right: 10px;
          span {
            vertical-align: middle;
          }
          img {
            margin-left: 5px;
            vertical-align: middle;
          }
        }
      }
      .sqft {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
.category {
  position: absolute;
  left: 10px;
  top: 15px;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 30px;
  cursor: pointer;
  &.listed {
    color: #0e6027;
    background-color: #a7f0ba;
  }
  &.draft {
    color: #a2191f;
    background-color: #ffd7d9;
  }
  &.review {
    color: #8B8000;
    background-color: #ece8b8;
  }
}
.add-properties-step {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 75%;
  @media screen and (max-width: 1055px) {
    width: 100%;
  }
  .cds--progress-step {
    width: 100%;
    min-width: auto;
    .cds--progress-line {
      width: 100%;
    }
  }
}
.properties-step-box {
  width: 70%;
  @media screen and (min-width: 768px) {
    background-color: $gray;
  }
  @media screen and (max-width: 1055px) {
    width: 100%;
  }

  .step-inner {
    @media screen and (min-width: 768px) {
      padding: 20px;
    }
    .cds--text-input,
    .cds--date-picker__input,
    .cds--text-area {
      background-color: #fff;
    }
  }
  .step-btn {
    display: flex;
    @media screen and (max-width: 767px) {
      justify-content: flex-end;
      padding-bottom: 20px;
    }
    .cds--btn {
      max-width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 10px;
      min-width: 120px;
      @media screen and (min-width: 768px) {
        margin-left: 0px;
        min-height: 60px;
        width: 50%;
      }
      &:last-child {
        @media screen and (min-width: 768px) {
          margin-left: auto;
        }
      }
    }
  }
}
.edit-property-wrap {
  margin-top: 20px;
  > h4 {
    font-size: 22px;
  }
  > p {
    font-size: 14px;
  }
  .properties-step-box {
    margin-top: 30px;
    background-color: #fff;
    .step-inner {
      padding: 0;
      .cds--text-input,
      .cds--date-picker__input,
      .cds--text-area {
        background-color: $gray-300;
      }
    }
    .step-btn {
      justify-content: flex-start;
      .cds--btn {
        align-items: center;
        min-height: 48px;
        width: auto;
        margin: 0;
        min-width: 100px;
      }
    }
  }
}

.red-text {
  color: $red;
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.notification-icon .cds--popover {
  margin-right: 150px;
  left: -14px !important;
}
.cds--loading-overlay,
.main-loader {
  z-index: 9999;
}
.main-loader {
  width: 50px;
  height: 50px;
}
.loader-bg .cds--loading-overlay {
  z-index: 9999;
  position: absolute !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.section-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.black-button {
  background-color: #161616;
  width: 150px;
  max-height: 50px;
}
.search-box {
  margin-bottom: 10px;
  .cds--form-item {
    flex: none !important;
    // margin-left: 10px;
  }
}
.search-box input {
  width: 400px;
  height: 50px;
  margin-right: 10px;
}

.search-box .cds--stack-vertical {
  display: flex;
}
.filter-select {
  width: 25%;
  margin-right: 10px;
}
.filter-box {
  display: flex;
  margin-bottom: 5px;
  // flex-wrap: wrap;
  .cds--popover {
    filter: none !important;
  }
  .cds--label {
    display: none;
  }
  .cds--select-input {
    height: 50px;
  }
}
.checkbox-wraper {
  align-items: center;
  justify-content: space-between;
  .checkbox-align {
    display: flex;
    align-items: center;
  }
  .cds--toggle {
    width: 100%;
    .cds--toggle__appearance {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
}
.cds--toast-notification {
  position: fixed;
  z-index: 9999;
  top: 6% !important;
  right: 1% !important;
}
.displayFlex {
  display: flex;
}
.upload_text {
  display: flex;
  flex-wrap: wrap;
  input[type='file'] {
    display: none;
  }
  .upload-image {
    position: relative;
    margin-top: 15px;
    margin-right: 15px;
    > img {
      width: 80px;
      height: 80px;
    }
    span {
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
      img {
        border-radius: 100%;
      }
    }
  }
  .file-upload {
    margin-top: 15px;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .cds--form-item {
    display: none !important;
  }
}
.property-detail-wrap {
  > p {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .lead-appli-info {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 480px) {
      display: block;
    }
    .property-lead-applicant {
      order: 1;
      @media screen and (max-width: 480px) {
        display: flex;
      }
      span {
        padding: 14px 20px;
        background-color: $gray-100;
        color: $dark;
        margin-right: 10px;
        display: inline-block;
        @media screen and (max-width: 480px) {
          width: 50%;
          &:last-child {
            margin-right: 0;
          }
        }
        b {
          font-weight: bold;
        }
      }
    }
    .property-images {
      order: 3;
      width: 100%;
      margin-top: 20px;
      position: relative;
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        display: block;
        height: 400px;
        object-fit: cover;
        @media screen and (max-width: 767px) {
          height: 300px;
        }
        @media screen and (max-width: 480px) {
          height: 250px;
        }
      }
    }
    .property-applicant {
      order: 2;
      .cds--btn {
        min-width: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 991px) {
          min-width: 170px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          max-width: 100%;
        }
        span {
          margin: 0;
        }
        img {
          width: 20px;
        }
      }
    }
  }
  .listing-row {
    margin-top: 40px;
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    h5 {
      font-size: 20px;
      margin-bottom: 15px;
      @media screen and (max-width: 991px) {
        font-size: 18px;
      }
    }
    p {
      color: $dark;
      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
    .overview-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      li {
        width: 25%;
        padding: 0 15px;
        font-size: 18px;
        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
        @media screen and (max-width: 991px) {
          width: 50%;
          &:not(:nth-child(-n + 2)) {
            margin-top: 20px;
          }
        }
        img {
          width: 40px;
          height: 40px;
          vertical-align: middle;
          margin-right: 15px;
          @media screen and (max-width: 1024px) {
            width: 30px;
            height: 30px;
          }
          @media screen and (max-width: 480px) {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
        span {
          vertical-align: middle;
        }
      }
    }
    .listings-list {
      display: flex;
      flex-wrap: wrap;
      max-width: 500px;
      li {
        width: 50%;
        padding: 10px 15px 10px 35px;
        font-size: 18px;
        position: relative;
        @media screen and (max-width: 991px) {
          font-size: 16px;
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 12px;
          width: 20px;
          height: 10px;
          border-left: 1px solid $dark;
          border-bottom: 1px solid $dark;
          transform: rotate(-45deg);
        }
      }
    }
    .details-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      li {
        width: 33.33%;
        padding: 0 15px;
        margin-top: 30px;
        @media screen and (min-width: 768px) {
          &:nth-child(-n + 3) {
            margin-top: 20px;
          }
        }
        @media screen and (max-width: 767px) {
          width: 50%;
          &:nth-child(-n + 2) {
            margin-top: 20px;
          }
        }
        span {
          font-size: 16px;
          color: $dark;
        }
        h6 {
          font-size: 20px;
          font-weight: bold;
          @media screen and (max-width: 991px) {
            font-size: 16px;
          }
        }
      }
    }
    .amenites-utilities-list {
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
      li {
        width: 33.33%;
        padding: 15px;
        background-color: $gray-200;
        border: 1px solid $dark-300;
        display: flex;
        align-items: center;
        margin-left: -1px;
        @media screen and (max-width: 991px) {
          width: 50%;
        }
        @media screen and (max-width: 600px) {
          width: max-content;
          display: inline-flex;
          margin-bottom: 10px;
          padding: 10px 15px;
        }
        img {
          margin-right: 15px;
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}
.unlist-modal {
  .cds--modal {
    background-color: rgba(255, 255, 255, 0.7);
    .cds--modal-container {
      max-width: 600px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    }
    .unlisted-btn {
      margin: 50px -16px -10px;
      .cds--btn {
        width: 50%;
        justify-content: flex-start;
        min-height: 60px;
      }
    }
  }
}
.swiper {
  width: 100%;
  height: 100%;

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.applicants-form {
  padding: 30px 0;
  @media screen and (max-width: 767px) {
    padding: 15px 0;
  }
  .cds--col {
    max-width: 33.33%;
    flex: 0 0 33.33%;
    @media screen and (max-width: 1024px) {
      max-width: 50%;
      flex: 0 0 50%;
    }
    @media screen and (max-width: 480px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  .cds--btn {
    height: 48px;
    width: 100px;
    justify-content: flex-start;
  }
}
.applicants-action {
  display: flex;
  justify-content: center;
  span {
    margin: 0 10px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}
.applicants-table-wrap {
  padding-bottom: 20px;
  .cds--data-table {
    thead {
      th {
        white-space: nowrap;
      }
    }
    tbody {
      td {
        white-space: nowrap;
      }
    }
  }
}
.applicants-listing-title {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  h4 {
    font-size: 24px;
    margin-right: 15px;
    font-weight: bold;
    @media screen and (min-width: 768px) {
      font-size: 20px;
    }
  }
  .cds--btn {
    background-color: $gray-300;
    color: $primary;
    padding: 7px 20px;
  }
}
.applicants-listing-filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-left: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  @media screen and (min-width: 768px) {
    border-bottom: 1px solid $gray-100;
    margin-top: 0;
  }
  .cds--btn {
    margin-left: 10px;
    img {
      width: 18px;
      height: 18px;
      margin-right: 0;
    }
  }
}
.table-search-box {
  max-width: 500px;
  display: flex;
  position: relative;
  margin-bottom: 20px;
  svg {
    position: absolute;
    left: 15px;
    top: 12px;
    z-index: 9;
  }
  .cds--text-input {
    padding-left: 45px;
    border: 0;
  }
  .cds--btn {
    min-width: 110px;
    margin-left: 15px;
    justify-content: flex-start;
  }
}
.applicants-view-modal {
  .cds--modal-container {
    width: 100%;
    background-color: #fff;
    @media screen and (min-width: 768px) {
      margin-left: 17rem;
    }
    .cds--row {
      margin: 0;
      .cds--col {
        padding-left: 0;
        padding-right: 0;
        @media screen and (min-width: 480px) {
          border-bottom: 1px solid $dark-300;
          margin-bottom: 20px;
          padding-bottom: 10px;
        }
        .cds--form-item {
          padding-right: 20px;
        }
      }
    }
    .cds--list-box {
      border: 0;
    }
    input,
    .cds--list-box__field {
      padding: 0;
      border: 0;
      pointer-events: none;
    }
  }
  .cds--list-box__menu-icon {
    display: none;
  }
  .cds--date-picker-input__wrapper {
    svg {
      display: none;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.statistics-component{
  border-radius: 10px;
  padding: 0rem 1.5rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .statistics-box{
    display: inline-flex;
    flex-direction: column;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 10px;
    padding: 0.5rem 3rem 0.5rem 1rem;
    width: 31%;
    margin-top: 2rem;
    @media screen and (max-width: 1300px) {
      width: 31%;
    }
    @media screen and (max-width: 1200px) {
      width: 48%;
    }
    @media screen and (max-width: 900px) {
      padding: 0.5rem 3rem 0.5rem 0.5rem;
    }
    @media screen and (max-width: 765px) {
      padding: 0.5rem 3rem 0.5rem 1rem;
      width: 48%;
    }
    @media screen and (max-width: 430px) {
      padding: 0.5rem 3rem 0.5rem 0.5rem;
    }
    .statistics-box-data{
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      .statistics-box-image{
        height: 50px;
        width: 50px;
        margin-right: 10px;
        @media screen and (max-width: 900px) {
          height: 40px;
          width: 40px;
          margin-right: 5px;
        }
        @media screen and (max-width: 765px) {
          height: 50px;
          width: 50px;
          margin-right: 10px;
        }
        @media screen and (max-width: 550px) {
          height: 30px;
          width: 30px;
        }
        @media screen and (max-width: 430px) {
          margin-right: 5px;
        }
      }
      .statistics-box-number{
        font-weight: bolder;
        font-size: 3rem;
        @media screen and (max-width: 900px) {
          font-size: 2rem;
        }
        @media screen and (max-width: 765px) {
          font-size: 3rem;
        }
        @media screen and (max-width: 550px) {
          font-size: 2rem;
        }
      }
    }
    .statistics-box-name{
      margin-left: 35px;
      font-weight: 500;
      font-size: 1rem;
    }
  }
}

.charts-component{
  display: flex;
  padding: 0rem 0.5rem;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  .chart-holder{
    padding: 1rem;
    border: 3px solid black;
    border-right: none;
    border-left: none;
    margin-top: 1.5rem;
  }
}